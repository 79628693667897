import React from "react";
import "./Games.css";
import gameIcon from "../../images/game-icon.svg";

const Games = () => {
  return (
    <section className="eksp-games mg-top">
      <div className="eksp-games__container">
        <h2 className="eksp-games__header col-yellow">Uwolnij zabawę</h2>
        <p className="eksp-games__small-header">Dostępne gry:</p>
        <ul className="eksp-games__list">
          <li className="eksp-games__item">
            <img
              src={gameIcon}
              alt="game icon"
              title="game icon"
              width="33"
              height="30"
            />
            Automaty do gier dla tych, którzy chcą sprawdzić swoje szczęście
          </li>
          <li className="eksp-games__item">
            <img
              src={gameIcon}
              alt="game icon"
              title="game icon"
              width="33"
              height="30"
            />
            Klasyki gier zręcznościowych i nowoczesne ulubione gry dla
            entuzjastów gier
          </li>
          <li className="eksp-games__item">
            <img
              src={gameIcon}
              alt="game icon"
              title="game icon"
              width="33"
              height="30"
            />
            Sporty stołowe, takie jak piłkarzyki i bilard dla przyjaznych
            zawodów
          </li>
          <li className="eksp-games__item">
            <img
              src={gameIcon}
              alt="game icon"
              title="game icon"
              width="33"
              height="30"
            />
            Opcje zakładów sportowych:
          </li>
          <li className="eksp-games__item">
            <img
              src={gameIcon}
              alt="game icon"
              title="game icon"
              width="33"
              height="30"
            />
            Zakłady na piłkę nożną, koszykówkę, tenis i nie tylko
          </li>
          <li className="eksp-games__item">
            <img
              src={gameIcon}
              alt="game icon"
              title="game icon"
              width="33"
              height="30"
            />
            Aktualizacje w czasie rzeczywistym i kursy na różne wydarzenia
            krajowe i międzynarodowe
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Games;
