import React from "react";
import "./About.css";
import { useLocation, Link } from "react-router-dom";

const About = () => {
  const location = useLocation();

  return (
    <section className="eksp-about mg-top">
      {location.pathname === "/about-us" ? (
        <h1 className="eksp-about__header">O nas</h1>
      ) : (
        <h2 className="eksp-about__header">O nas</h2>
      )}
      <div className="eksp-about__body">
        <p className="t-20 col-yellow text-center">
          Naszą misją jest oferowanie szczegółowych i wciągających recenzji,
          które oddają istotę każdego miejsca, wydobywając na światło dzienne
          tętniącą życiem atmosferę, w której łączą się sport na żywo,
          ekscytujące możliwości gry oraz wyjątkowe jedzenie i napoje.
        </p>
        <p className="t-20 text-center">
          Kierując się pasją do sportu i rozrywki, nasz oddany zespół
          niestrudzenie pracuje nad stworzeniem listy najlepszych barów
          sportowych, które zaspokoją potrzeby zarówno zagorzałych entuzjastów
          sportu, jak i zwykłych gości. Rozumiemy, jak ważne jest znalezienie
          odpowiedniego miejsca do oglądania zmagań ulubionych drużyn,
          obstawiania zakładów i delektowania się pyszną ofertą kulinarną.
        </p>
        <div className="eksp-about__img"></div>
        <p className="t-20 text-center">
          W Ekspert Automatow wierzymy, że każdy bar sportowy ma swój
          niepowtarzalny urok i historię. Naszym celem jest pomóc Ci odkryć te
          ukryte perełki, zapewniając niezapomniane wrażenia za każdym razem,
          gdy wejdziesz do jednego z polecanych przez nas miejsc. Dołącz do nas
          w tej ekscytującej podróży po tętniącym życiem krajobrazie barów
          sportowych w Polsce i pozwól nam poprowadzić Cię do kolejnego
          ulubionego miejsca spotkań.
        </p>
      </div>
    </section>
  );
};

export default About;
