import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import "./Bars.css";
import star from "../../images/star-1.svg";
import pin from "../../images/pin.svg";

const Bars = () => {
  const [isVisibleBarOne, setIsVisibleBarOne] = useState(false);
  const [isVisibleBarTwo, setIsVisibleBarTwo] = useState(false);
  const [isVisibleBarThree, setIsVisibleBarThree] = useState(false);
  const location = useLocation();

  return (
    <section className="eksp-bars">
      {location.pathname === "/bars" ? (
        <h1 className="eksp-bars__header col-blue">Najlepsze bary</h1>
      ) : (
        <h2 className="eksp-bars__header col-blue">Najlepsze bary</h2>
      )}
      <ul className="eksp-bars__list">
        <li className="eksp-bars__bar">
          <div className="eksp-bars__btn-container">
            <p
              className="eksp-bars__more t-24 col-yellow"
              onClick={() => setIsVisibleBarOne(!isVisibleBarOne)}
            >
              {isVisibleBarOne ? "zawalić się" : "więcej"}
            </p>
            <Link
              to="https://www.tripadvisor.com/Restaurant_Review-g274772-d21080292-Reviews-442_Sport_Pub-Krakow_Lesser_Poland_Province_Southern_Poland.html"
              className={`eksp-bars__link t-24 ${isVisibleBarOne && "open"}`}
              target="_blank"
            >
              Zarezerwować
            </Link>
          </div>

          <div className="eksp-bars__visible">
            <div className="eksp-bars__text-container">
              <p className="eksp-bars__bar-header">442 Sport Pub</p>
              <div className="eksp-bars__rating t-24">
                <img
                  src={star}
                  alt="star"
                  title="star"
                  width="30"
                  height="30"
                />
                4.5
              </div>
              <div className="eksp-bars__adress t-24">
                <img src={pin} alt="pin" title="pin" width="30" height="30" />
                ul. Swietego Tomasza 20, Krakow 31-020 Poland
              </div>
              <p className="eksp-bars__text t-20">
                Położony w samym sercu Krakowa, przy ulicy Świętego Tomasza 20,
                442 Sport Pub jest doskonałym miejscem zarówno dla entuzjastów
                sportu, jak i zwykłych klientów. Z imponującą oceną 4,5, ten bar
                sportowy zyskał reputację dzięki tętniącej życiem atmosferze i
                wyjątkowej obsłudze.
              </p>
              <p className="eksp-bars__text t-20 col-yellow">
                Po wejściu do środka goście zanurzają się w tętniącym życiem
                otoczeniu, w którym wyczuwalna jest energia transmisji
                sportowych na żywo. Ściany ozdobione są pamiątkami sportowymi,
                tworząc autentyczną i zachęcającą przestrzeń, która celebruje
                sportową doskonałość. Wiele ekranów o wysokiej rozdzielczości
                zapewnia, że każde miejsce oferuje doskonały widok na akcję,
                dzięki czemu jest to idealne miejsce do oglądania piłki nożnej,
                rugby, koszykówki i nie tylko.
              </p>
            </div>
            <div className="img-container eksp-bars__img-container-1"></div>
          </div>
          <div className={`eksp-bars__hidden ${isVisibleBarOne && "visible"}`}>
            <div className="img-container eksp-bars__img-container-2"></div>
            <div className="eksp-bars__text-container">
              <p className="eksp-bars__text t-20">
                442 Sport Pub oferuje menu, które zaspokoi wszystkie gusta.
                Klienci mogą delektować się różnorodnym wyborem obfitych
                klasyków pubowych i dań kuchni międzynarodowej, fachowo
                przygotowanych w celu uzupełnienia szerokiej gamy napojów. Od
                piw rzemieślniczych i wybornych win po kreatywne koktajle -
                każdy znajdzie tu coś dla siebie.
              </p>
              <p className="eksp-bars__text t-20 col-yellow">
                Tym, co wyróżnia 442 Sport Pub, są jego specjalne udogodnienia i
                funkcje. Bar oferuje wieczory tematyczne, quizy i występy
                muzyczne na żywo, które dodają dodatkową warstwę emocji do
                każdej wizyty. Niezależnie od tego, czy spędzasz czas z
                przyjaciółmi, czy spotykasz się z innymi fanami sportu, ciepły i
                przyjazny personel zapewnia niezapomniane wrażenia.
              </p>
              <p className="eksp-bars__text t-20">
                W przypadku rezerwacji lub zapytań, goście proszeni są o kontakt
                z pubem pod numerem +48 504 266 500. Niezależnie od tego, czy
                jesteś mieszkańcem, czy gościem odwiedzającym Kraków, 442 Sport
                Pub obiecuje niezrównaną atmosferę, w której sport i spotkania
                towarzyskie łączą się w doskonałej harmonii.
              </p>
            </div>
          </div>
        </li>

        <li className="eksp-bars__bar">
          <div className="eksp-bars__btn-container">
            <p
              className="eksp-bars__more t-24 col-yellow"
              onClick={() => setIsVisibleBarTwo(!isVisibleBarTwo)}
            >
              {isVisibleBarTwo ? "zawalić się" : "więcej"}
            </p>
            <Link
              to="https://www.tripadvisor.co/Attraction_Review-g274772-d7309337-Reviews-Football_Heaven_Sports_Bar-Krakow_Lesser_Poland_Province_Southern_Poland.html"
              className={`eksp-bars__link t-24 ${isVisibleBarTwo && "open"}`}
              target="_blank"
            >
              Zarezerwować
            </Link>
          </div>
          <div className="eksp-bars__visible">
            <div className="eksp-bars__text-container">
              <p className="eksp-bars__bar-header">
                Football Heaven Sports Bar
              </p>
              <div className="eksp-bars__rating t-24">
                <img
                  src={star}
                  alt="star"
                  title="star"
                  width="30"
                  height="30"
                />
                4.0
              </div>
              <div className="eksp-bars__adress t-24">
                <img src={pin} alt="pin" title="pin" width="30" height="30" />
                ul. Sw. Filipa 7, Cracovia 31-150 Polonia
              </div>
              <p className="eksp-bars__text t-20">
                Położony w tętniącym życiem mieście Cracovia, przy ul. Sw.
                Filipa 7, Football Heaven Sports Bar naprawdę zasługuje na swoją
                nazwę, oferując niezrównane wrażenia dla entuzjastów sportu. To
                tętniące życiem miejsce jest skarbnicą zarówno dla mieszkańców,
                jak i turystów, szukających miejsca, w którym miłość do sportu
                spotyka się z wyjątkową gościnnością.
              </p>
              <p className="eksp-bars__text t-20 col-yellow">
                Atmosfera w Football Heaven jest elektryczna, tętniąca emocjami
                i oczekiwaniem. Duże ekrany o wysokiej rozdzielczości
                strategicznie rozmieszczone w całym barze zapewniają, że każdy
                gość ma miejsce w pierwszym rzędzie na najnowsze mecze piłki
                nożnej i inne ekscytujące wydarzenia sportowe. Wystrój jest
                hołdem dla świata sportu, z pamiątkami i akcentami tematycznymi,
                które wywołują poczucie koleżeństwa i pasji.
              </p>
            </div>
            <div className="img-container eksp-bars__img-container-3"></div>
          </div>
          <div className={`eksp-bars__hidden ${isVisibleBarTwo && "visible"}`}>
            <div className="img-container eksp-bars__img-container-4"></div>
            <div className="eksp-bars__text-container">
              <p className="eksp-bars__text t-20">
                Obsługa w Football Heaven jest na najwyższym poziomie, z
                przyjaznym i uważnym personelem gotowym zaspokoić każdą
                potrzebę. Dokładają oni wszelkich starań, aby goście czuli się
                mile widziani i komfortowo, niezależnie od tego, czy są
                zagorzałymi fanami piłki nożnej, czy też po prostu lubią
                niezobowiązujące wypady z przyjaciółmi.
              </p>
              <p className="eksp-bars__text t-20 col-yellow">
                Menu jest atrakcją samą w sobie, oferując szeroką gamę
                przepysznych dań i napojów. Od klasycznych dań pubowych po
                bardziej wyrafinowane, każdy znajdzie tu coś dla siebie. Bar
                jest dobrze zaopatrzony w wybór lokalnych i międzynarodowych
                piw, wyśmienitych win i kreatywnych koktajli, idealnych do
                łączenia z posiłkiem lub świętowania zwycięstwa.
              </p>
              <p className="eksp-bars__text t-20">
                Football Heaven wyróżnia się także specjalnymi wydarzeniami i
                wieczorami tematycznymi, które dodatkowo wzbogacają atmosferę,
                oferując niezapomniane przeżycia każdemu fanowi sportu.
              </p>
            </div>
          </div>
        </li>

        <li className="eksp-bars__bar">
          <div className="eksp-bars__btn-container">
            <p
              className="eksp-bars__more t-24 col-yellow"
              onClick={() => setIsVisibleBarThree(!isVisibleBarThree)}
            >
              {isVisibleBarThree ? "zawalić się" : "więcej"}
            </p>
            <Link
              to="https://www.tripadvisor.com/Restaurant_Review-g274725-d12335032-Reviews-Grass_Irish_Sports_Pub-Gdansk_Pomerania_Province_Northern_Poland.html"
              className={`eksp-bars__link t-24 ${isVisibleBarThree && "open"}`}
              target="_blank"
            >
              Zarezerwować
            </Link>
          </div>
          <div className="eksp-bars__visible">
            <div className="eksp-bars__text-container">
              <p className="eksp-bars__bar-header">Grass Irish Sports Pub</p>
              <div className="eksp-bars__rating t-24">
                <img
                  src={star}
                  alt="star"
                  title="star"
                  width="30"
                  height="30"
                />
                4.0
              </div>
              <div className="eksp-bars__adress t-24">
                <img src={pin} alt="pin" title="pin" width="30" height="30" />
                ul. Szeroka 121/122 Gdansk 80-835 Poland
              </div>
              <p className="eksp-bars__text t-20">
                Położony przy ulicy Szerokiej 121/122 w historycznym Gdańsku,
                Grass Irish Sports Pub oferuje autentyczną irlandzką gościnność
                połączoną z pasją do sportu. Ten uroczy pub jest rajem zarówno
                dla mieszkańców, jak i turystów, zapewniając ciepłą i
                zachęcającą atmosferę, która oddaje ducha Irlandii.
              </p>
              <p className="eksp-bars__text t-20 col-yellow">
                Po wejściu do środka, odwiedzających wita przytulne wnętrze
                ozdobione irlandzkim wystrojem, tworząc przyjazną atmosferę
                idealną do spotkań z przyjaciółmi lub innymi miłośnikami sportu.
                Pub jest wyposażony w wiele dużych ekranów strategicznie
                rozmieszczonych, aby każdy gość mógł cieszyć się transmisjami na
                żywo z szerokiej gamy wydarzeń sportowych, od piłki nożnej i
                rugby po mniej znane lokalne ulubione.
              </p>
            </div>
            <div className="img-container eksp-bars__img-container-5"></div>
          </div>
          <div
            className={`eksp-bars__hidden ${isVisibleBarThree && "visible"}`}
          >
            <div className="img-container eksp-bars__img-container-6"></div>
            <div className="eksp-bars__text-container">
              <p className="eksp-bars__text t-20">
                Obsługa w Grass Irish Sports Pub jest wyjątkowa, a przyjazny i
                kompetentny personel sprawia, że każdy gość czuje się jak w
                domu. Goście mogą oczekiwać uważnej obsługi, niezależnie od
                tego, czy są doświadczonymi bywalcami, czy też po raz pierwszy
                odkrywają tętniące życiem gdańskie życie nocne.
              </p>
              <p className="eksp-bars__text t-20 col-yellow">
                Jedzenie i napoje są wyróżniającą się cechą pubu, z menu, które
                oferuje wspaniały wachlarz tradycyjnych irlandzkich potraw i
                międzynarodowych klasyków pubowych. Od obfitych gulaszów i ryb z
                frytkami po innowacyjne przekąski barowe - każdy znajdzie tu coś
                dla siebie. Bar jest dobrze zaopatrzony w różnorodny wybór
                irlandzkich whisky, lokalnych piw i charakterystycznych
                koktajli, dzięki czemu każdy może znaleźć idealnego drinka.
              </p>
              <p className="eksp-bars__text t-20">
                To, co naprawdę wyróżnia Grass Irish Sports Pub, to wyjątkowe
                doświadczenia i wydarzenia. Wieczory tematyczne, muzyka na żywo
                i specjalne pokazy sportowe tworzą atmosferę koleżeństwa i
                ekscytacji, której trudno dorównać. Jest to miejsce, w którym
                fani sportu mogą zjednoczyć się we wspólnej pasji, co czyni go
                idealnym miejscem zarówno dla mieszkańców, jak i turystów, aby
                zanurzyć się w tętniącej życiem kulturze sportowej Gdańska.
              </p>
            </div>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default Bars;
