import React from "react";
import "./Main.css";
import Bars from "../Bars/Bars";
import About from "../About/About";
import Games from "../Games/Games";

// import MainPopup from "../Popup/MainPopup";

const MainCover = () => {
  return (
    <main className="eksp-main">
      {/* <MainPopup/> */}
      <section className="eksp-main__cover">
        <h1 className="eksp-main__header">
          3 najlepsze sport bary w Polsce, gdzie można obstawiać zakłady
          sportowe
        </h1>
        <p className="t-28 col-yellow text-center">
          Zanurz się w świecie polskich barów sportowych, oglądaj mecze i
          obstawiaj zakłady
        </p>
      </section>

      <section className="eksp-description mg-top">
        <h2 className="eksp-description__header">
          Witamy w Ekspert Automatow!
        </h2>
        <div className="eksp-description__body">
          <div className="eksp-description__img"></div>
          <div className="eksp-description__text-container">
            <p className="eksp-description__text t-20">
              Witamy w Ekspert Automatow, najlepszym przewodniku po najlepszych
              barach sportowych w całej Polsce. Zanurz się w świecie
              ekscytujących wydarzeń sportowych na żywo, gdzie każdy mecz ożywa
              niezrównanymi emocjami. W każdym z naszych starannie
              wyselekcjonowanych miejsc możesz zanurzyć się w adrenalinie gier
              na żywo, kibicując swoim ulubionym drużynom i zawodnikom. Dla
              tych, którzy uwielbiają dodatkowe emocje, nasze polecane bary
              oferują najwyższej klasy udogodnienia do obstawiania zakładów,
              dodając kolejną warstwę emocji do oglądania sportu.
            </p>
            <p className="eksp-description__text t-20 col-yellow">
              Ale to nie wszystko - te miejsca to nie tylko sport. Są to
              tętniące życiem centra rozrywki, oferujące zróżnicowany wachlarz
              zajęć, które zaspokoją wszystkie gusta. Niezależnie od tego, czy
              jesteś zapalonym graczem, który chce zaangażować się w
              interaktywne gry, czy kimś, kto chce spędzić wieczór z
              przyjaciółmi, znajdziesz coś, co Cię zainteresuje. Nasze bary
              sportowe są wyposażone w najnowocześniejsze strefy gier, w których
              można rywalizować i dobrze się bawić, jednocześnie ciesząc się
              żywą atmosferą.
            </p>
            <p className="eksp-description__text t-20">
              Ekspert Automatow zapewnia, że każdy lokal na naszej liście
              spełnia wysokie standardy gościnności i rozrywki, zapewniając nie
              tylko miejsce do oglądania sportu, ale także prawdziwą
              społeczność. Niezależnie od tego, czy jesteś entuzjastą sportu,
              okazjonalnym graczem, czy po prostu szukasz dynamicznego wieczoru,
              nasza wyselekcjonowana oferta obiecuje niezapomniane wrażenia.
              Dołącz do nas i odkryj to, co najlepszego ma do zaoferowania
              polska scena barów sportowych, gdzie każda wizyta zamienia się w
              niezapomnianą przygodę pełną emocji i przyjemności.
            </p>
          </div>
        </div>
      </section>

      <Bars />
      <Games/>
      <About/>
    </main>
  );
};

export default MainCover;
