import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import age from "../../images/age.svg";
import inst from "../../images/instagram.svg";
import telegram from "../../images/telegram.svg";

const Footer = () => {
  return (
    <footer id="contacts" className="eksp-footer">
      <div className="eksp-footer__el1">
        <p className="t-20 col-blue">
          Jeśli chcesz się z nami skontaktować lub przekazać swoją opinię,
          jesteś mile widziany. Możesz skontaktować się z nami poprzez e-mail:
          <a
            href="mailto:ekspertautomatow@gmail.com"
            className="eksp-footer__email col-blue"
          >
            ekspertautomatow@gmail.com
          </a>
        </p>
        <p className="eksp-footer__cookie-header col-blue t-24">
          Ważne informacje:
        </p>
        <p className="t-20 col-blue">
          ekspertautomatow.com używa plików cookie, aby zapewnić najwyższą
          możliwą jakość. Odwiedzając stronę, akceptujesz korzystanie z plików
          cookie.
        </p>
        <Link to="/cookies" className="t-20 col-blue eksp-footer__link">
          Polityka plików cookie
        </Link>
      </div>
      <div className="eksp-footer__el2">
        <p className="t-20 col-blue">
          Informacje prezentowane na naszej stronie internetowej są przeznaczone
          wyłącznie do celów informacyjnych.
        </p>
        <Link to="/privacy" className="t-20 col-blue eksp-footer__link">
          Polityka prywatności
        </Link>
      </div>
      <div className="eksp-footer__el3">
        <img
          src={age}
          alt="age limit 18+"
          title="age limit 18+"
          width="54"
          height="54"
        />
        <div className="eksp-footer__links-container">
          <a href="#">
            <img src={inst} alt="instagram icon" title="instagram icon" />
          </a>
          <a href="#">
            <img src={telegram} alt="telegram icon" title="telegram icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
